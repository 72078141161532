
















































import { Component, Vue } from 'vue-property-decorator';
import { DocumentsTableHeader, DocumentsTable } from '@/components';
import { formatDate } from '@projetlucie/lc-front-sdk-old';
import { contextEmployeur, demo, documents, toast } from '@/store';
import { Document, InputModelFilterDoc } from '@/models';
import { filteredDocuments, showDocument } from '@/helpers';
import { DocumentServices } from '@/services';

@Component({
  methods: { formatDate },
  components: { DocumentsTableHeader, DocumentsTable }
})
export default class Documents extends Vue {
  private documents: Document[] | null = null;
  private selectedCategorie: InputModelFilterDoc = {
    id: '-1',
    name: 'Tous',
    types: null
  };
  private searchValue = '';

  // Paginations system
  private perPage = 10;
  private currentPage = 1;
  private rowsNumber = 0;

  async mounted() {
    await this.initDocuments();
    if (this.$route.query.nature) {
      await this.applyFilterFromQuery(this.$route.query.nature as string);
    }
    if (this.$route.query.q) {
      this.searchValue = this.$route.query.q as string;
    }
    this.setDocumentsFiltered();
  }

  private async initDocuments() {
    if (this.getNpm) {
      await documents.getDocuments({ npm: this.getNpm, demo: this.getDemo });
    }
  }

  private async applyFilterFromQuery(query: string) {
    this.selectedCategorie = this.getCategories?.filter(c => c.id === query)[0] as InputModelFilterDoc;
  }

  private setDocumentsFiltered() {
    let filtered: Document[] | null = this.getDocuments;
    if (this.selectedCategorie.id !== '-1' && filtered) {
      filtered = filteredDocuments(filtered, this.selectedCategorie);
    }
    if (this.searchValue) {
      const updatedSearchValue = this.searchValue.split('*').join('.*');
      const regex = new RegExp(updatedSearchValue, 'i');
      filtered = filtered?.filter(el => regex.test(el.nom ?? '')) ?? null;
    }
    this.documents = filtered;
  }

  private downloadDocument(document: Document) {
    if (document && document.id && this.getNpp) {
      DocumentServices.downloadDocument(document.id, this.getNpp, document.nom);
    } else {
      toast.pushError(this.$t('common.error.unknown').toString());
    }
  }

  private visualiserDocument(document: Document) {
    if (document && document.id && this.getNpp) {
      showDocument(document, this.getNpp);
    } else {
      toast.pushError(this.$t('common.error.unknown').toString());
    }
  }

  get getDocuments() {
    return documents.documents;
  }

  get getCategories() {
    return documents.categories;
  }

  get getNpp() {
    return contextEmployeur.getEmployeur?.numeroPersonne;
  }

  get getNpm() {
    return contextEmployeur.getEmployeur?.numeroPersonneMorale;
  }

  get getDemo() {
    return demo.demoState;
  }
}
